const Footer = ({ className = "" }) => {
    const mdPadding = className.includes("md:pt-") ? " " : "md:pt-10"; 
    const Padding = className.includes("pt-") ? " " : "pt-52"; 

    return (
        <footer className={`site-footer ${Padding} ${mdPadding} ${className} text-white text-center bg-transparent`}>
            <div className="footer-content p-1 bg-secondary">
                <div className="footer-section social-media p-3">
                    <h3>Follow Me</h3>
                    <a className="instagram-link" href="https://www.instagram.com/umit_tamyol" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                </div>

                <div className="footer-section contact-info p-3">
                    <h3>Contact</h3>
                    <p>MAIL: <a href="mailto:UEMIT.TAMYOL@GMAIL.COM">UEMIT.TAMYOL@GMAIL.COM</a></p>
                </div>

                <div className="footer-section copyright p-3">
                    <p>&copy; 2024 Ümit Tamyol. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
