import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <nav className="bg-black text-white sticky">
            <div className="flex h-16 p-4 items-center justify-between">
                {/* LOGO */}
                <Link to="/" className="text-5xl navbar-text-animation md:hidden">UT</Link>

                {/* Hamburger Icon for Mobile */}
                <div className="md:hidden">
                    <button onClick={toggleMenu}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                        </svg>
                    </button>
                </div>

                {/* Menu for desktop */}
                <div className="hidden md:flex space-x-4 items-center justify-between w-full">
                    <div className="flex items-center space-x-4">
                        <Link to="/" className="text-5xl navbar-text-animation">UT</Link>
                        <Link to="/fights" className="text-xl navbar-text-animation">FIGHTS</Link>
                        <Link to="/aboutme" className="text-xl navbar-text-animation">ABOUT ME</Link>
                        <Link to="/gallery" className="text-xl navbar-text-animation">GALLERY</Link>
                    </div>
                    <Link to="/contact" className="text-xl navbar-text-animation">CONTACT</Link>
                </div>

                {/* mobile sidebar */}
                <div className={`fixed top-0 right-0 h-full w-2/3 bg-black text-white 
                    transform ${isOpen ? "translate-x-0" : "translate-x-full"}
                    transition-transform duration-300 ease-in-out md:hidden`}>
                    <button
                    className="text-2xl p-4 w-full"
                    onClick={toggleMenu}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 384 512"><path fill="#ffffff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    </button>
                    <div className="flex flex-col justify-between h-4/5">
                        <ul className="flex flex-col items-center space-y-4 mt-20 text-4xl">
                            <li>
                                <Link to="/fights" className="navbar-text-animation" onClick={toggleMenu}>FIGHTS</Link>
                            </li>
                            <li>
                                <Link to="/aboutme" className="navbar-text-animation" onClick={toggleMenu}>ABOUT ME</Link>
                            </li>
                            <li>
                                <Link to="/gallery" className="navbar-text-animation" onClick={toggleMenu}>GALLERY</Link>
                            </li>
                        </ul>
                        <ul className="flex flex-col items-center text-4xl">
                            <li>
                                <Link to="/contact" className="navbar-text-animation" onClick={toggleMenu}>CONTACT</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;