import clip1 from '../assets/videos/clip_1.mp4';
import clip2 from '../assets/videos/clip_2.mp4';
import clip3 from '../assets/videos/clip_3.mp4';

const VideoContainer = () => {
    return (
        <div className="video-container flex justify-center gap-20 flex-wrap mt-20">
            <video className='fight-clip white-shadow w-72 h-auto rounded-md' src={clip1} autoPlay loop muted playsInline disablePictureInPicture></video>
            <video className='fight-clip white-shadow w-72 h-auto rounded-md' src={clip2} autoPlay loop muted playsInline disablePictureInPicture></video>
            <video className='fight-clip white-shadow w-72 h-auto rounded-md' src={clip3} autoPlay loop muted playsInline disablePictureInPicture></video>
        </div>
    );
}

export default VideoContainer;