import PropTypes from 'prop-types';

const ImageCard = ({imgLink}) => {
    return (
        <div className="flex w-full md:w-1/4 ">
            <img className="w-full h-auto rounded-md white-shadow" src={imgLink} alt="fight in action img" />
        </div>
    );
}

ImageCard.propTypes = {
    imgLink: PropTypes.string.isRequired,
};

export default ImageCard;