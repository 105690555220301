import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home'
import Fights from './pages/Fights';
import AboutMe from './pages/AboutMe';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';

const App = () => {
    return (
        <Router>
            <div className='flex flex-col h-screen'>
                <Navbar/>

                <div className="flex flex-grow flex-col">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/fights" element={<Fights />} />
                        <Route path="/aboutme" element={<AboutMe />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/contact" element={<Contact/>} />
                    </Routes>
                </div>
            </div>
        </Router>
    )
}

export default App;