import WelcomeText from "../components/WelcomeText";
import VideoContainer from "../components/VideoContainer"
import Footer from "../components/Footer";

const Dashboard = () => {
    return (
        <div>
            <WelcomeText />
            <VideoContainer/>
            <Footer className="pt-14 md:pt-20"/>
        </div>
    );
}

export default Dashboard;