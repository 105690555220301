import ImageCard from "../components/ImageCard";
import img1 from "../assets/images/1.jpg";
import img2 from "../assets/images/2.jpg";
import img3 from "../assets/images/3.jpg";
import img4 from "../assets/images/4.jpg";
import img5 from "../assets/images/5.jpg";
import img6 from "../assets/images/6.jpg";
import img7 from "../assets/images/7.jpg";
import img8 from "../assets/images/8.jpg";
import img9 from "../assets/images/9.jpg";
import img10 from "../assets/images/10.jpg";
import img11 from "../assets/images/11.jpg";
import Footer from "../components/Footer";


const Gallery = () => {
    return (
        <div className="flex flex-col">
            <div className="flex justify-around flex-wrap gap-10 p-8">
                <ImageCard
                imgLink={img8}/>
                <ImageCard
                imgLink={img7}/>
                <ImageCard
                imgLink={img9}/>
                <ImageCard
                imgLink={img3}/>
                <ImageCard
                imgLink={img5}/>
                <ImageCard
                imgLink={img4}/>
                <ImageCard
                imgLink={img2}/>
                <ImageCard
                imgLink={img1}/>
                <ImageCard
                imgLink={img6}/>
                <ImageCard
                imgLink={img10}/>
                <ImageCard
                imgLink={img11}/>
            </div>
            <Footer className="pt-5"/>
        </div>
        
    );
}

export default Gallery;