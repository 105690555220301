import FightContainer from "../components/FightContainer";
import Footer from "../components/Footer";


const Fights = () => {
    return (
        <div className="flex flex-col">
            <div className="flex h-screen flex-col flex-grow">
                <div className="flex flex-wrap mt-10 gap-10 p-2 md:flex-row md:gap-0 md:mt-0 h-5/6 items-center justify-center">
                    <FightContainer
                    title="ÜMIT TAMYOL VS GILMAR TRUJILLO - WSFC 7"
                    date="7. September 2024"
                    location="Vorarlberg, Austria"
                    videoLink="https://www.youtube.com/embed/WzBAsNQqUq0"
                    />
                    <FightContainer
                    title="ÜMIT TAMYOL VS AMIR NEJATI - INNFERNO 6"
                    date="24. June 2023"
                    location="Innsbruck, Austria"
                    videoLink="https://www.youtube.com/embed/YEsuaE-vqC4"
                    />
                </div>

            </div>
            <Footer/>
        </div>
        

        
    );
}

export default Fights;