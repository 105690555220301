const Contact = () => {
    return (
        <div class="flex flex-col justify-center items-center gap-6 mb-16 text-center text-white h-full">
            
                <div class="text-2xl p-1">
                    <h3>Follow Me</h3>
                    <a class="instagram-link" href="https://www.instagram.com/umit_tamyol">INSTAGRAM</a>    
                </div>               
                <div class="text-2xl p-1">
                    <p>MAIL: <a href="mailto:UEMIT.TAMYOL@GMAIL.COM">UEMIT.TAMYOL@GMAIL.COM</a></p>
                </div>                    
                <div class="text-2xl p-1">
                    <p>&copy; 2024 Ümit Tamyol. All Rights Reserved.</p>                
                </div>
        </div>
    );
}

export default Contact;