import PropTypes from 'prop-types';

const FightContainer = ({ title, date, location, videoLink }) => {
    return (
        <div className="fight-container flex flex-col justify-center items-center w-full md:w-2/4 h-3/5 text-white space-y-4">
            <h2 className="text-2xl text-center animate-character">{title}</h2>
            <div className="video-wrapper w-10/12 h-full">
                <iframe title={title} className="fight-link w-full h-full" src={videoLink} allowFullScreen></iframe>
            </div>
            <p>Date: {date}</p>
            <p>Location: {location}</p>
        </div>
    );
}

FightContainer.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    videoLink: PropTypes.string.isRequired,
};

export default FightContainer;