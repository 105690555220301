import StoryCard from "../components/StoryCard";
import bgImage from "../assets/images/1.jpg"
import Footer from "../components/Footer";
import styles from '../styles/index.css';

const AboutMe = () => {
    const style = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
    }

    return (
        <div>
            <div style={style} className="flex flex-col md:flex-row items-center justify-center md:h-screen gap-5 p-7 md:p-4 text-white">
                <StoryCard
                title="MY JOURNEY"
                text="I began my MMA journey with 16 years old, drawn to the sport's discipline and challenge. Over the years, I've faced setbacks and victories that have shaped who I am. Each fight teaches me something new, pushing me to improve every day."/>
                <StoryCard
                title="TRAINING PHILOSOPHY"
                text="I focus on a balanced approach to training technique, conditioning, and mental strength. I believe that the right mindset is just as important as physical skill. My goal is to keep evolving and stay prepared for every challenge."/>
                <StoryCard
                title="VISION"
                text="I want to inspire others to pursue their goals in martial arts. Through my journey, I hope to show that hard work and dedication can lead to success. My aim is to build a community that values discipline and perseverance in the sport."/>
                <StoryCard
                title="GOALS"
                text="My goal is to push my limits and become a top competitor in MMA. I strive to improve every day, embracing both victories and challenges as steps towards excellence. I’m committed to honing my skills, learning from each fight, and growing as an athlete and as a person."/>
            </div>
            <Footer className="pt-0 md:pt-0"/>
        </div>
    );
}

export default AboutMe;