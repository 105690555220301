import React from 'react'; // Importiere React
import ReactDOM from 'react-dom/client'; // Importiere ReactDOM für das Rendering
import App from './App'; // Importiere die Hauptanwendungskomponente
import './styles/index.css'; // Importiere die CSS-Datei für das Styling

// Erstelle einen Root-Container für die React-App
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render die App-Komponente im Root-Container
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);