const WelcomeText = () => {
    return (
        <div className="welcome-text text-white text-5xl font-light mt-12 p-4">
            I am <span className="animate-character text-7xl font-normal  ">Ümit Tamyol</span><br />
            a fighter with a passion for martial arts.
            <br />
            <div className="secondary-text text-4xl mt-12">
                Every fight is a challenge that helps me grow<br />
                not just as an athlete<br />
                but as a person.
            </div>
        </div>
    );
};

export default WelcomeText